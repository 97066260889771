import { OMEGADB_URL } from "config";
import axios from "axios";

const fetchCandidates = async ({ url, callback }) => {
  try {
    const baseUrl = `${OMEGADB_URL}${url}`;

    const response = await axios.get(baseUrl);

    if (response && response.status === 200) {
      callback(response.data);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export { fetchCandidates };
