import React from "react";
import PropTypes from "prop-types";
import { Col } from "reactstrap";
import AsyncSelect from "react-select/async";
import { getUserDetails } from "config";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NumericFormat } from "react-number-format";
import CustomSelect from "components/customs/dropdown";
import {
  DropdownIndicator,
  addProjectDropdownStyles,
  addProjectSearchInputStyle,
  canEditRoles,
  congRoles,
  fundSourceDBMOptions,
  fundSourceOptions,
} from "utility/constants";
import { Toast, getFormattedContactNumber } from "utility/helpers";
import FormGenertor from "components/customs/form-generator/input";
import {
  fetchInstitutions,
  fetchMunicipalities,
  fetchProvinces,
} from "services/services.projects";
import CustomCreatableSelect from "components/customs/creatable-select";
import { fetchAtcOptions } from "services/services.directory";
import {
  DeleteRowIcon,
  HouseMemberColRight,
  HouseMemberSection,
  StyledRed,
} from "../styled";
import Others from "./Others";
import CustomDropzone from "./Dropzone";

const RequiredIndicator = <StyledRed>{"*"}</StyledRed>;

const allocationTypeOptions = [
  {
    value: 1,
    label: "Regular",
  },
  {
    value: 2,
    label: "Alagang Tingog Center",
  },
  {
    value: 3,
    label: "Others",
  },
];

const ATCcongs = [234, 5, 235];

const institutionTypes = [
  {
    label: "Private",
    value: "Private",
  },
  {
    label: "Government",
    value: "Government",
  },
];

const acceptedFileTypes = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const AddProgramForm = ({
  agency,
  handleSearchInputOnChange,
  index,
  handleCongressmanSearch,
  recipient,
  projectRecipients,
  handleDeleteRow,
  setProjectRecipients,
  selectedProject,
  handleUploadedFiles,
  handleDeleteFile,
  handleDeleteAllFiles,
  uploadedFiles,
  isFormResetted,
  setIsFormResetted,
  regionOptions,
}) => {
  const userDetails = getUserDetails();
  const [facilityType, setFacilityType] = React.useState(null);
  const [facilityOptions, setFacilityOptions] = React.useState([]);
  const [selectedInstitution, setSelectedInstitution] = React.useState(null);

  const [selectedProvince, setSelectedProvince] = React.useState(null);
  const [provinceOptions, setProvinceOptions] = React.useState([]);
  const [selectedMunicipality, setSelectedMunicipality] = React.useState(null);
  const [municipalityOptions, setMunicipalityOptions] = React.useState([]);

  const [atcOptions, setAtcOptions] = React.useState([]);
  const [selectedFundSource, setSelectedFundSource] = React.useState([]);

  const isRoleCongressman = congRoles.includes(userDetails.type);

  const fetchAllInstitutions = async () => {
    const param =
      // eslint-disable-next-line no-nested-ternary
      agency === 8
        ? recipient?.recipientName?.region?.id
          ? `?ownership=${facilityType?.value}&type=Healthcare&regionId=${
              recipient?.recipientName?.region?.id || null
            } `
          : `?ownership=${facilityType?.value}&type=Healthcare`
        : "?type=Educational";

    await fetchInstitutions({
      param,
      callback: (data) => {
        if (data.length) {
          setFacilityOptions(
            data.map((hospital) => ({
              ...hospital,
              id: hospital.id,
              label: hospital.name,
              value: hospital.id,
            }))
          );
        }
      },
    });
  };

  const fetchAllProvinces = async () => {
    await fetchProvinces({
      param: recipient?.recipientName?.region?.value,
      callback: (data) => {
        if (data.length) {
          const options = data.map(({ id, name, omegaId }) => ({
            label: name,
            value: id,
            omegaId,
          }));

          options.unshift({
            label: "All Provinces",
            value: "All Provinces",
          });

          setSelectedProvince(options[0]);
          setProvinceOptions(options);
        } else {
          setProvinceOptions([]);
        }
      },
    });
  };

  const fetchAllMunicipalities = async () => {
    await fetchMunicipalities({
      param: selectedProvince?.value,
      callback: (data) => {
        if (data?.length) {
          const options = data.map(({ id, name, omegaId }) => ({
            label: name,
            value: id,
            omegaId,
          }));

          options.unshift({
            label: "All Municipalities",
            value: "All Municipalities",
          });

          setSelectedMunicipality(options[0]);
          setMunicipalityOptions(options);
        } else {
          setMunicipalityOptions([]);
        }
      },
    });
  };

  const resetForm = (allocType) => {
    setSelectedInstitution(null);
    setSelectedProvince(null);
    setSelectedMunicipality(null);
    setFacilityType(null);
    setSelectedFundSource(null);

    const newProjectRecipients = [...projectRecipients];

    newProjectRecipients[index] = {
      ...newProjectRecipients[index],
      justification: "",
      allocationType: allocType,
      budgetAllocation: "",
      projectTitle: "",
      institution: "",
      otherInfo: "",
      slots: "",
      implementingOffice: "",
      notes: null,
      allocationContactNumber: "",
      allocationContactName: "",
      recipientName: {
        ...newProjectRecipients[index].recipientName,
        region: null,
      },
    };

    setProjectRecipients(newProjectRecipients);
    setIsFormResetted(false);
  };

  const isOtherFormShown = recipient?.allocationType === 3;

  const isATCDropdownShown = recipient?.allocationType === 2;

  const isImplementingOfficeShown =
    agency === 14 && recipient?.allocationType !== 2;

  const isTrackingCodeShown =
    agency === 3 &&
    selectedProject === 14 &&
    (recipient?.allocationType === 1 || recipient?.allocationType === 3);

  const isProjectTitleShown =
    (agency === 14 || (agency === 3 && selectedProject === 14)) &&
    ((agency === 3 &&
      (recipient?.allocationType === 1 || recipient?.allocationType === 3)) ||
      (agency === 14 && recipient?.allocationType !== 2));

  const fetchAllATC = async () => {
    await fetchAtcOptions({
      params: {
        // eslint-disable-next-line camelcase
        region_id: recipient?.recipientName?.region?.omegaId,
        // eslint-disable-next-line camelcase
        province_id:
          recipient?.Province?.value !== "All Provinces"
            ? recipient?.Province?.omegaId
            : null,
        // eslint-disable-next-line camelcase
        municipality_id:
          recipient?.Municipality?.value !== "All Municipality"
            ? recipient?.Municipality?.omegaId
            : null,
      },
      callback: (data) => {
        const options = data
          ?.filter((atc) => atc.principal)
          ?.map((atc) => ({
            ...atc,
            label: `${atc?.last_name}, ${atc?.first_name}`,
            value: atc?.id,
          }));

        if (options.length !== 0) {
          const newProjectRecipients = [...projectRecipients];

          const firstOption = options[0];

          newProjectRecipients[index] = {
            ...newProjectRecipients[index],
            // eslint-disable-next-line max-len
            allocationName: `${firstOption?.last_name}, ${firstOption?.first_name}`,
            allocationContactNumber: getFormattedContactNumber(
              firstOption?.principal?.atc_partner_contact
            ),
            allocationContactName: firstOption?.principal?.atc_partner_last_name
              ? // eslint-disable-next-line max-len
                `${firstOption?.principal?.atc_partner_first_name} ${firstOption?.principal?.atc_partner_last_name}`
              : firstOption?.principal?.atc_partner_first_name,
            allocationReferenceKey: firstOption?.principal?.id,
            allocation: {
              atcId: firstOption?.id,
              principalId: firstOption?.principal?.id,
              managerFirstName:
                firstOption?.principal?.atc_partner_manager_first_name,
              managerLastName:
                firstOption?.principal?.atc_partner_manager_last_name,
              managerContact:
                firstOption?.principal?.atc_partner_manager_contact,
            },
          };

          setProjectRecipients(newProjectRecipients);
        }

        setAtcOptions(options);
      },
    });
  };

  const resetSelectedATC = () => {
    const newProjectRecipients = [...projectRecipients];

    newProjectRecipients[index] = {
      ...newProjectRecipients[index],
      allocationName: "",
      allocationContactNumber: "",
      allocationContactName: "",
      allocationReferenceKey: "",
      allocation: "",
    };

    setProjectRecipients(newProjectRecipients);
  };

  React.useEffect(() => {
    if (recipient?.Municipality) {
      if (isATCDropdownShown) fetchAllATC();
      resetSelectedATC();
    }
  }, [recipient?.Municipality]);

  React.useEffect(() => {
    if (recipient?.Province) {
      if (isATCDropdownShown) fetchAllATC();
      resetSelectedATC();
    }
  }, [recipient?.Province]);

  React.useEffect(() => {
    if (selectedProvince) {
      fetchAllMunicipalities();

      if (selectedProvince.value === "All Provinces") {
        setSelectedMunicipality({
          label: "All Municipalities",
          value: "All Municipalities",
        });
      }
    }
  }, [selectedProvince]);

  React.useEffect(() => {
    if (recipient?.recipientName?.region) {
      resetSelectedATC();
      fetchAllProvinces();
      if (isATCDropdownShown) fetchAllATC();

      setSelectedMunicipality(null);
      setSelectedProvince(null);

      if (agency === 8) fetchAllInstitutions();
    }
  }, [recipient?.recipientName?.region]);

  React.useEffect(() => {
    if (isATCDropdownShown) fetchAllATC();
  }, [recipient?.allocationType]);

  React.useEffect(() => {
    if (facilityType) {
      fetchAllInstitutions();
      setSelectedInstitution(null);
    }
  }, [facilityType]);

  React.useEffect(() => {
    if (agency === 27) {
      fetchAllInstitutions();
    }

    if (agency === 8) {
      const newProjectRecipients = [...projectRecipients];

      newProjectRecipients[index] = {
        ...newProjectRecipients[index],
        facilityType: institutionTypes[0],
      };

      setProjectRecipients(newProjectRecipients);
      setFacilityType(institutionTypes[0]);
    }
  }, [agency]);

  React.useEffect(() => {
    if (isFormResetted) {
      resetForm();
    }
  }, [isFormResetted]);

  const generateForm = () => {
    let agencyForm = null;
    let additionalFields = null;

    switch (agency) {
      case 8:
        agencyForm = (
          <>
            <HouseMemberSection>
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">
                  {"Type of Facility"}
                  {RequiredIndicator}
                </h5>
                <p>{"Provide the type of facility"}</p>
              </Col>
              <HouseMemberColRight>
                <CustomSelect
                  placeholder="Type of Facility"
                  styles={addProjectDropdownStyles}
                  components={{ DropdownIndicator }}
                  className="asyc-dropdown"
                  classNamePrefix="select"
                  options={institutionTypes}
                  onChange={(option) => {
                    const newProjectRecipients = [...projectRecipients];

                    newProjectRecipients[index] = {
                      ...newProjectRecipients[index],
                      facilityType: option,
                    };

                    setProjectRecipients(newProjectRecipients);
                    setFacilityType(option);
                  }}
                  value={facilityType}
                />
              </HouseMemberColRight>
            </HouseMemberSection>

            <HouseMemberSection>
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">
                  {"Name of Facility"}
                  {RequiredIndicator}
                </h5>
                <p>{"Provide the name of facility"}</p>
              </Col>
              <HouseMemberColRight>
                <CustomCreatableSelect
                  placeholder="Name of Facility"
                  styles={addProjectDropdownStyles}
                  components={{ DropdownIndicator }}
                  className="asyc-dropdown"
                  classNamePrefix="select"
                  options={facilityOptions}
                  onChange={(option) => {
                    const newProjectRecipients = [...projectRecipients];

                    newProjectRecipients[index] = {
                      ...newProjectRecipients[index],
                      institution: option,
                    };

                    setProjectRecipients(newProjectRecipients);
                    setSelectedInstitution(option);
                  }}
                  value={selectedInstitution}
                />
              </HouseMemberColRight>
            </HouseMemberSection>
          </>
        );
        break;

      case 27:
        additionalFields =
          selectedProject === 10 || selectedProject === 15 ? (
            <>
              <HouseMemberSection>
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">
                    {"Total Slots"}
                    {RequiredIndicator}
                  </h5>
                  <p>{"Provide the number of slots"}</p>
                </Col>
                <HouseMemberColRight>
                  <NumericFormat
                    className="input-date form-control"
                    placeholder="Slots"
                    value={recipient?.slots}
                    decimalSeparator="."
                    thousandSeparator=","
                    isAllowed={(values) => {
                      const { floatValue, value } = values;

                      return floatValue === parseInt(value, 10) || "";
                    }}
                    onValueChange={(value) => {
                      const { floatValue } = value;

                      const newProjectRecipients = [...projectRecipients];

                      newProjectRecipients[index] = {
                        ...newProjectRecipients[index],
                        slots: floatValue,
                      };

                      setProjectRecipients(newProjectRecipients);
                    }}
                  />
                </HouseMemberColRight>
              </HouseMemberSection>

              <HouseMemberSection>
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">
                    {"Supporting Documents"}
                    {RequiredIndicator}
                  </h5>
                  <p>{"Please provide the Qualification Map of the TVI"}</p>
                </Col>
                <HouseMemberColRight>
                  <CustomDropzone
                    handleUploadedFiles={handleUploadedFiles}
                    handleDeleteFile={handleDeleteFile}
                    uploadedFiles={uploadedFiles}
                    label="Upload Attachments"
                  />
                </HouseMemberColRight>
              </HouseMemberSection>
            </>
          ) : null;
        break;

      default:
        break;
    }

    switch (agency) {
      case 27:
        agencyForm =
          (selectedProject === 10 || selectedProject === 15) &&
          projectRecipients[index].allocationType !== 2 ? (
            <HouseMemberSection>
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">
                  {"Name of TVI"}
                  {RequiredIndicator}
                </h5>
                <p>{"Provide the name of the TVI"}</p>
              </Col>
              <HouseMemberColRight>
                <CustomCreatableSelect
                  placeholder="Name of TVI"
                  styles={addProjectDropdownStyles}
                  components={{ DropdownIndicator }}
                  className="asyc-dropdown"
                  classNamePrefix="select"
                  options={facilityOptions}
                  onChange={(option) => {
                    const newProjectRecipients = [...projectRecipients];

                    newProjectRecipients[index] = {
                      ...newProjectRecipients[index],
                      institution: option,
                    };

                    setProjectRecipients(newProjectRecipients);
                    setSelectedInstitution(option);
                  }}
                  value={selectedInstitution}
                />
              </HouseMemberColRight>
            </HouseMemberSection>
          ) : null;
        break;

      case 14:
        additionalFields =
          projectRecipients[index].allocationType !== 2 ? (
            <>
              {canEditRoles.includes(userDetails.type) && (
                <HouseMemberSection>
                  <Col
                    xs="12"
                    md="6"
                  >
                    <h5 className="font-weight-bold">
                      {"Fund Source"}
                      {RequiredIndicator}
                    </h5>
                    <p>{"Provide the fund source"}</p>
                  </Col>
                  <HouseMemberColRight>
                    <CustomSelect
                      placeholder="Fund Source"
                      styles={addProjectDropdownStyles}
                      components={{ DropdownIndicator }}
                      className="asyc-dropdown"
                      classNamePrefix="select"
                      options={fundSourceOptions}
                      onChange={(option) => {
                        const newProjectRecipients = [...projectRecipients];

                        newProjectRecipients[index] = {
                          ...newProjectRecipients[index],
                          fundSource: option,
                        };

                        setProjectRecipients(newProjectRecipients);
                        setSelectedFundSource(option);
                      }}
                      value={selectedFundSource}
                    />
                  </HouseMemberColRight>
                </HouseMemberSection>
              )}

              <HouseMemberSection>
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">
                    {"Supporting Documents"}
                    {RequiredIndicator}
                  </h5>
                  <p>{"Upload the supporting documents"}</p>
                </Col>
                <HouseMemberColRight>
                  <CustomDropzone
                    handleUploadedFiles={handleUploadedFiles}
                    handleDeleteFile={handleDeleteFile}
                    uploadedFiles={uploadedFiles}
                    label="Upload Attachments"
                    acceptedFileTypes={acceptedFileTypes}
                    acceptedFileTypeErrorMsg="Accepted files are only xls and xlxs."
                  />
                </HouseMemberColRight>
              </HouseMemberSection>
            </>
          ) : null;
        break;

      case 3:
        additionalFields =
          selectedProject === 14 &&
          canEditRoles.includes(userDetails.type) &&
          projectRecipients[index].allocationType !== 2 ? (
            <HouseMemberSection>
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">{"Fund Source"}</h5>
                <p>{"Provide the fund source"}</p>
              </Col>
              <HouseMemberColRight>
                <CustomSelect
                  placeholder="Fund Source"
                  styles={addProjectDropdownStyles}
                  components={{ DropdownIndicator }}
                  className="asyc-dropdown"
                  classNamePrefix="select"
                  options={fundSourceDBMOptions}
                  onChange={(option) => {
                    const newProjectRecipients = [...projectRecipients];

                    newProjectRecipients[index] = {
                      ...newProjectRecipients[index],
                      fundSource: option,
                    };

                    setProjectRecipients(newProjectRecipients);
                    setSelectedFundSource(option);
                  }}
                  value={selectedFundSource}
                />
              </HouseMemberColRight>
            </HouseMemberSection>
          ) : null;
        break;

      default:
        break;
    }

    return (
      <>
        <HouseMemberSection>
          <Col
            xs="12"
            md="6"
          >
            <h5 className="font-weight-bold">
              {"House Member"}
              {RequiredIndicator}
            </h5>
            <p>{"Provide the house member's name"}</p>
          </Col>
          <Col
            xs="12"
            md="6"
          >
            <div className="d-flex">
              <AsyncSelect
                className="asyc-dropdown w-100"
                classNamePrefix="select"
                placeholder="House Member"
                onChange={(option) => {
                  handleSearchInputOnChange(option, index);
                }}
                loadOptions={handleCongressmanSearch}
                styles={addProjectSearchInputStyle}
                value={recipient?.recipientName}
                isDisabled={isRoleCongressman}
              />

              <DeleteRowIcon
                aria-hidden
                onClick={(e) =>
                  projectRecipients.length > 1
                    ? handleDeleteRow(e, index)
                    : null
                }
              >
                <FontAwesomeIcon
                  size="xl"
                  color={projectRecipients.length < 2 ? "#9FA5AA" : "#4252C9"}
                  icon={faCircleXmark}
                  style={{
                    cursor:
                      projectRecipients.length < 2 ? "default" : "pointer",
                  }}
                />
              </DeleteRowIcon>
            </div>
          </Col>
        </HouseMemberSection>

        {isProjectTitleShown && (
          <HouseMemberSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Project Title"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the project title"}</p>
            </Col>
            <HouseMemberColRight>
              <FormGenertor
                type="text"
                placeholder="Project Title"
                className="input-date form-control"
                value={recipient?.institution || ""}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;

                  const newProjectRecipients = [...projectRecipients];

                  newProjectRecipients[index] = {
                    ...newProjectRecipients[index],
                    institution: value,
                  };

                  setProjectRecipients(newProjectRecipients);
                }}
              />
            </HouseMemberColRight>
          </HouseMemberSection>
        )}

        {/* START DEPARTMENT OF BUDGET AND MANAGEMENT */}
        {isTrackingCodeShown && (
          <HouseMemberSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"LGSF Tracking Code"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the  LGSF tracking code"}</p>
            </Col>
            <HouseMemberColRight>
              <FormGenertor
                type="text"
                placeholder="LGSF Tracking Code"
                className="input-date form-control"
                value={recipient?.otherInfo || ""}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;

                  const newProjectRecipients = [...projectRecipients];

                  newProjectRecipients[index] = {
                    ...newProjectRecipients[index],
                    otherInfo: value,
                  };

                  setProjectRecipients(newProjectRecipients);
                }}
              />
            </HouseMemberColRight>
          </HouseMemberSection>
        )}
        {/* END DEPARTMENT OF BUDGET AND MANAGEMENT */}

        <HouseMemberSection>
          <Col
            xs="12"
            md="6"
          >
            <h5 className="font-weight-bold">
              {"Allocation Type"}
              {RequiredIndicator}
            </h5>
            <p>{"Provide the allocation type"}</p>
          </Col>
          <HouseMemberColRight>
            <CustomSelect
              placeholder="Allocation Type"
              components={{ DropdownIndicator }}
              classNamePrefix="select"
              styles={addProjectDropdownStyles}
              options={allocationTypeOptions}
              value={allocationTypeOptions.find(
                (type) =>
                  type.value === projectRecipients[index]?.allocationType
              )}
              onChange={({ value }) => {
                const newProjectRecipients = [...projectRecipients];

                newProjectRecipients[index] = {
                  ...newProjectRecipients[index],
                  allocationType: value,
                };

                if (value === 3) {
                  newProjectRecipients[index] = {
                    ...newProjectRecipients[index],
                    allocationType: value,
                    allocationContactName: "",
                    allocationContactNumber: null,
                  };
                }

                setProjectRecipients(newProjectRecipients);

                resetForm(value);
                handleDeleteAllFiles();
              }}
              isDisabled={
                isRoleCongressman ||
                !ATCcongs.includes(projectRecipients[index]?.recipientName?.id)
              }
            />
          </HouseMemberColRight>
        </HouseMemberSection>

        <HouseMemberSection>
          <Col
            xs="12"
            md="6"
          >
            <h5 className="font-weight-bold">
              {"Budget Allocation"}
              {RequiredIndicator}
            </h5>
            <p>{"Provide the respective budget allocation"}</p>
          </Col>
          <HouseMemberColRight>
            <NumericFormat
              className="input-date form-control"
              placeholder="Budget"
              value={recipient?.budgetAllocation}
              decimalSeparator="."
              thousandSeparator=","
              onValueChange={(value) => {
                const { floatValue } = value;

                const newProjectRecipients = [...projectRecipients];

                newProjectRecipients[index] = {
                  ...newProjectRecipients[index],
                  budgetAllocation: floatValue,
                };

                setProjectRecipients(newProjectRecipients);
              }}
            />
          </HouseMemberColRight>
        </HouseMemberSection>

        <HouseMemberSection>
          <Col
            xs="12"
            md="6"
          >
            <h5 className="font-weight-bold">
              {"Implementing Agency"}
              {RequiredIndicator}
            </h5>
            <p>{"Provide the implementing agency"}</p>
          </Col>

          <HouseMemberColRight>
            <CustomSelect
              placeholder="Please Select a Region"
              styles={addProjectDropdownStyles}
              components={{ DropdownIndicator }}
              className="asyc-dropdown"
              classNamePrefix="select"
              options={regionOptions}
              onChange={(option) => {
                const newProjectRecipients = [...projectRecipients];

                newProjectRecipients[index] = {
                  ...newProjectRecipients[index],
                  recipientName: {
                    ...newProjectRecipients[index].recipientName,
                    region: option,
                  },
                };

                setProjectRecipients(newProjectRecipients);
              }}
              value={
                recipient?.recipientName?.region
                  ? {
                      value: recipient?.recipientName?.region?.value,
                      label: recipient?.recipientName?.region?.label,
                    }
                  : null
              }
            />
          </HouseMemberColRight>
        </HouseMemberSection>

        {isImplementingOfficeShown && (
          <HouseMemberSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Implementing Office"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the implementing office"}</p>
            </Col>
            <HouseMemberColRight>
              <FormGenertor
                type="text"
                placeholder="Implementing Office"
                className="input-date form-control"
                value={recipient?.implementingOffice || ""}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;

                  const newProjectRecipients = [...projectRecipients];

                  newProjectRecipients[index] = {
                    ...newProjectRecipients[index],
                    implementingOffice: value,
                  };

                  setProjectRecipients(newProjectRecipients);
                }}
              />
            </HouseMemberColRight>
          </HouseMemberSection>
        )}

        <HouseMemberSection>
          <Col
            xs="12"
            md="6"
          >
            <h5 className="font-weight-bold">{"Province"}</h5>
            <p>{"Provide the province in your region"}</p>
          </Col>
          <HouseMemberColRight>
            <CustomSelect
              placeholder="Please Select a Province"
              styles={addProjectDropdownStyles}
              components={{ DropdownIndicator }}
              className="asyc-dropdown"
              classNamePrefix="select"
              options={provinceOptions}
              onChange={(option) => {
                const newProjectRecipients = [...projectRecipients];

                newProjectRecipients[index] = {
                  ...newProjectRecipients[index],
                  Province: option,
                };

                setProjectRecipients(newProjectRecipients);
                setSelectedProvince(option);
              }}
              value={selectedProvince}
            />
          </HouseMemberColRight>
        </HouseMemberSection>

        <HouseMemberSection>
          <Col
            xs="12"
            md="6"
          >
            <h5 className="font-weight-bold">{"Municipality"}</h5>
            <p>{"Provide the municipality in your province"}</p>
          </Col>
          <HouseMemberColRight>
            <CustomSelect
              placeholder="Please Select a Municipality"
              styles={addProjectDropdownStyles}
              components={{ DropdownIndicator }}
              className="asyc-dropdown"
              classNamePrefix="select"
              options={municipalityOptions}
              onChange={(option) => {
                const newProjectRecipients = [...projectRecipients];

                newProjectRecipients[index] = {
                  ...newProjectRecipients[index],
                  Municipality: option,
                };

                setProjectRecipients(newProjectRecipients);
                setSelectedMunicipality(option);
              }}
              value={selectedMunicipality}
              isDisabled={!recipient?.Province}
            />
          </HouseMemberColRight>
        </HouseMemberSection>

        {canEditRoles.includes(userDetails.type) && (
          <HouseMemberSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Editor Notes"}</h5>
              <p>{"Provide the editor notes"}</p>
            </Col>
            <HouseMemberColRight>
              <CustomCreatableSelect
                isMulti
                placeholder="Editor Notes"
                styles={addProjectDropdownStyles}
                components={{ DropdownIndicator }}
                className="asyc-dropdown"
                classNamePrefix="select"
                onChange={(option) => {
                  const newProjectRecipients = [...projectRecipients];

                  newProjectRecipients[index] = {
                    ...newProjectRecipients[index],
                    notes: option,
                  };

                  setProjectRecipients(newProjectRecipients);
                }}
                value={recipient?.notes}
              />
            </HouseMemberColRight>
          </HouseMemberSection>
        )}

        {isATCDropdownShown && (
          <HouseMemberSection>
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"ATC"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the ATC Branch"}</p>
            </Col>
            <HouseMemberColRight>
              <CustomSelect
                name="atc"
                placeholder="ATC"
                components={{ DropdownIndicator }}
                classNamePrefix="select"
                styles={addProjectDropdownStyles}
                options={atcOptions}
                value={
                  atcOptions.find(
                    (option) =>
                      option.principal.id ===
                      projectRecipients[index].allocationReferenceKey
                  ) || null
                }
                onChange={(value) => {
                  if (
                    !value?.principal?.atc_partner_first_name ||
                    value?.principal?.atc_partner_contact?.length < 10
                  ) {
                    Toast({
                      type: 4,
                      content: "ATC contact name and number cannot be empty",
                    });

                    return;
                  }

                  const newProjectRecipients = [...projectRecipients];

                  newProjectRecipients[index] = {
                    ...newProjectRecipients[index],
                    allocationName: `${value?.last_name}, ${value?.first_name}`,
                    allocationContactNumber: getFormattedContactNumber(
                      value?.principal?.atc_partner_contact
                    ),
                    allocationContactName: value?.principal
                      ?.atc_partner_last_name
                      ? // eslint-disable-next-line max-len
                        `${value?.principal?.atc_partner_first_name} ${value?.principal?.atc_partner_last_name}`
                      : value?.principal?.atc_partner_first_name,
                    allocationReferenceKey: value?.principal?.id,
                    allocation: {
                      atcId: value?.id,
                      principalId: value?.principal?.id,
                      managerFirstName:
                        value?.principal?.atc_partner_manager_first_name,
                      managerLastName:
                        value?.principal?.atc_partner_manager_last_name,
                      managerContact:
                        value?.principal?.atc_partner_manager_contact,
                    },
                  };

                  setProjectRecipients(newProjectRecipients);
                }}
              />
            </HouseMemberColRight>
          </HouseMemberSection>
        )}

        {agencyForm}

        {additionalFields}

        <HouseMemberSection>
          <Col
            xs="12"
            md="6"
          >
            <h5 className="font-weight-bold">{"Justification"}</h5>
            <p>{"Provide the justification"}</p>
          </Col>
          <HouseMemberColRight>
            <FormGenertor
              type="textarea"
              name="justification"
              placeholder="Enter Text Here"
              className="w-100 input-textarea form-control"
              rows="5"
              value={recipient?.justification || ""}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;

                const newProjectRecipients = [...projectRecipients];

                newProjectRecipients[index] = {
                  ...newProjectRecipients[index],
                  justification: value,
                };

                setProjectRecipients(newProjectRecipients);
              }}
            />
          </HouseMemberColRight>
        </HouseMemberSection>

        {isOtherFormShown && (
          <Others
            allocationName={
              projectRecipients[index]?.allocationContactName || ""
            }
            contact={projectRecipients[index]?.allocationContactNumber || ""}
            onChange={(key, value) => {
              const newProjectRecipients = [...projectRecipients];

              newProjectRecipients[index] = {
                ...newProjectRecipients[index],
                [key]: value,
              };

              setProjectRecipients(newProjectRecipients);
            }}
          />
        )}
      </>
    );
  };

  return generateForm();
};

AddProgramForm.prototype = {
  agency: PropTypes.number.isRequired,
  handleSearchInputOnChange: PropTypes.func,
  index: PropTypes.number.isRequired,
  handleCongressmanSearch: PropTypes.func,
  handleDeleteRow: PropTypes.func,
  setProjectRecipients: PropTypes.func,
  regionOptions: PropTypes.oneOfType([PropTypes.object]),
  recipient: PropTypes.oneOfType([PropTypes.object]),
  projectRecipients: PropTypes.oneOfType([PropTypes.object]),
  selectedProject: PropTypes.number,
};

export default AddProgramForm;
