import FormGenertor from "components/customs/form-generator/input";
import React, { useState, useEffect } from "react";
import { Col } from "reactstrap";
import { HouseMemberColRight, HouseMemberSection, StyledRed } from "../styled";

const RequiredIndicator = <StyledRed>{"*"}</StyledRed>;

const Others = ({ onChange, allocationName, contact }) => {
  const [name, setName] = useState(allocationName);
  const [contactNumber, setContactNumber] = useState(contact || "63");

  useEffect(() => {
    if (name !== allocationName) {
      setName(allocationName);
    }
  }, [allocationName, name]);

  useEffect(() => {
    setContactNumber(contact || "63");
  }, [contact]);

  return (
    <>
      <HouseMemberSection>
        <Col
          xs="12"
          md="6"
        >
          <h5 className="font-weight-bold">
            {"Recipient Contact"}
            {RequiredIndicator}
          </h5>
          <p>{"Provide the recipient contact"}</p>
        </Col>
        <HouseMemberColRight>
          <FormGenertor
            type="text"
            className="input-date form-control"
            placeholder="Recipient Name"
            value={name}
            onChange={(event) => {
              const {
                target: { value },
              } = event;
              setName(value);
              onChange("allocationContactName", value);
            }}
          />
        </HouseMemberColRight>
      </HouseMemberSection>

      <HouseMemberSection>
        <Col
          xs="12"
          md="6"
        >
          <h5 className="font-weight-bold">
            {"Contact Number"}
            {RequiredIndicator}
          </h5>
          <p>{"Provide the number of the recipient"}</p>
        </Col>
        <HouseMemberColRight>
          <FormGenertor
            type="mobile"
            className="input-date form-control"
            placeholder="Contact Number"
            value={contactNumber}
            onChange={(value) => {
              setContactNumber(value);
              onChange("allocationContactNumber", value);
            }}
          />
        </HouseMemberColRight>
      </HouseMemberSection>
    </>
  );
};

export default Others;
