import React, { useEffect } from "react";
import { getUserDetails } from "config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  changeDateRangeFrom,
  changeDateRangeTo,
} from "../../../redux/reducers/dateRange";

import "./style.scss";
import "flatpickr/dist/themes/material_blue.css";

const userDetails = getUserDetails();

const canUserSeeDatePicker =
  userDetails?.type === "Director" || userDetails?.type === "Editor";

const DateRange = () => {
  if (!canUserSeeDatePicker) {
    return null;
  }
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const dateRange = useSelector((state) => state.dateRange);

  useEffect(() => {
    if (!dateRange.dateFrom && !dateRange.dateTo) {
      const defaultDateFrom = new Date(2024, 0, 1);
      const currentDate = new Date();

      dispatch(
        changeDateRangeFrom(moment(defaultDateFrom).format("YYYY-MM-DD"))
      );
      dispatch(changeDateRangeTo(moment(currentDate).format("YYYY-MM-DD")));
    }
  }, [dateRange]);

  const handleOnChangeFrom = (selectedDate) => {
    const formattedDate = moment(selectedDate[0]).format("YYYY-MM-DD");
    dispatch(changeDateRangeFrom(formattedDate));
  };

  const handleOnChangeTo = (selectedDate) => {
    const formattedDate = moment(selectedDate[0]).format("YYYY-MM-DD");
    dispatch(changeDateRangeTo(formattedDate));
    navigate(0);
  };

  return (
    <div className="input-group mb-3 date-range-wrapper">
      <Flatpickr
        value={dateRange.dateFrom}
        placeholder="select date"
        className="date-picker"
        options={{ position: "below center" }}
        onChange={handleOnChangeFrom}
      />
      <FontAwesomeIcon
        className="arrow-right"
        icon={faArrowRight}
      />
      <Flatpickr
        value={dateRange.dateTo}
        placeholder="select date"
        className="date-picker"
        options={{ position: "below center" }}
        onChange={handleOnChangeTo}
      />
    </div>
  );
};

export default DateRange;
