import React from "react";
import Home from "views/Home";
import Representatives from "views/representatives";
import DistrictRepresentatives from "views/representatives/district";
import Projects from "views/projects";
import PartyAffiliation from "views/party";
import ProjectComponent from "views/projects/detail";
import ProjectProgram from "views/projects/detail/program";
import PartyComponent from "views/party/detail";
import CongressRepresentative from "views/representatives/congressman";
import AddProject from "views/projects/add";
import AccountList from "views/accounts/list";
import Account from "views/accounts/detail";
import ChangePassword from "views/auth/change-password";
import SummaryExport from "views/summary/export";
import Directory from "views/directory";
import CongressmanDetails from "views/directory/congressman";
import AgencyComponent from "views/directory/agency";
import DirectorDetails from "views/directory/director";
import GovernorDetails from "views/directory/governor";
import ViceGovernorDetails from "views/directory/viceGovernor";
import SenatorDetails from "views/directory/senator";
import MayorDetails from "views/directory/mayor";
import ViceMayorDetails from "views/directory/viceMayor";
import { getUserDetails } from "config";
import { hasPermission } from "utility/helpers";
import RecipientDetails from "views/projects/form";
import { canEditRoles } from "utility/constants";
import NewsAndAnnouncement from "views/news";
import AddAnnouncement from "views/news/add";
import NotificationsLogs from "views/logs";
import UtilizationReport from "views/utilization";
import AddUtilization from "views/utilization/add";
import NationalAgenciesComponent from "views/directory/nationalAgencies";

export const privateRoutes = [
  {
    exact: true,
    path: "/",
    element: <Home />,
    HasPermisson: hasPermission("landing"),
  },
  {
    exact: true,
    path: "/home",
    element: <Home />,
    HasPermisson: hasPermission("landing"),
  },
  {
    exact: true,
    path: "/representatives",
    element: <Representatives />,
    HasPermisson: hasPermission("congressman-view"),
  },
  {
    exact: true,
    path: "/representatives/:id",
    element: <DistrictRepresentatives />,
    HasPermisson: hasPermission("congressman-view"),
  },
  {
    exact: true,
    path: "/representatives/:id/cong/:congID",
    element: <CongressRepresentative />,
    HasPermisson: hasPermission("congressman-view"),
  },
  {
    exact: true,
    path: "/projects",
    element: <Projects />,
    HasPermisson: hasPermission("project-view"),
  },
  {
    exact: true,
    path: "/projects/:id",
    element: <ProjectComponent />,
    HasPermisson: hasPermission("project-view"),
  },
  {
    exact: true,
    path: "/projects/:id/program/:programID",
    element: <ProjectProgram />,
    HasPermisson: hasPermission("project-view"),
  },
  {
    exact: true,
    path: "/parties",
    element: <PartyAffiliation />,
    HasPermisson: hasPermission("party-view"),
  },
  {
    exact: true,
    path: "/parties/:id",
    element: <PartyComponent />,
    HasPermisson: hasPermission("party-view"),
  },
  {
    exact: true,
    path: "/add-project",
    element: <AddProject />,
    HasPermisson: hasPermission("project-create"),
  },
  {
    exact: true,
    path: "/accounts",
    element: <AccountList />,
    HasPermisson:
      canEditRoles.includes(getUserDetails()?.type) ||
      getUserDetails()?.type === "Master" ||
      getUserDetails()?.type === "Congressman",
  },
  {
    exact: true,
    path: "/add-account",
    element: <Account />,
    HasPermisson: hasPermission("user-create"),
  },
  {
    exact: true,
    path: "/account",
    element: <Account />,
    HasPermisson: hasPermission("user-update"),
  },
  {
    exact: true,
    path: "/change-password",
    element: <ChangePassword />,
    HasPermisson: hasPermission("user-password-change-others"),
  },
  {
    exact: true,
    path: "/summary/export",
    element: <SummaryExport />,
    HasPermisson: hasPermission("summary-view"),
  },
  {
    exact: true,
    path: "/utilization",
    element: <UtilizationReport />,
    HasPermisson: hasPermission("utilization-view"),
  },
  {
    exact: true,
    path: "/utilization/add",
    element: <AddUtilization />,
    HasPermisson: hasPermission("utilization-create"),
  },
  {
    exact: true,
    path: "/utilization/edit",
    element: <AddUtilization />,
    HasPermisson: hasPermission("utilization-update"),
  },
  {
    exact: true,
    path: "/utilization/view",
    element: <AddUtilization />,
    HasPermisson: hasPermission("utilization-view"),
  },
  {
    exact: true,
    path: "/directory/:view",
    element: <Directory />,
    HasPermisson: canEditRoles.includes(getUserDetails()?.type),
  },
  {
    exact: true,
    path: "/directory",
    element: <Directory />,
    HasPermisson: canEditRoles.includes(getUserDetails()?.type),
  },
  {
    exact: true,
    path: "/cong",
    element: <CongressmanDetails />,
    HasPermisson: hasPermission("congressman-update"),
  },
  {
    exact: true,
    path: "/agency",
    element: <AgencyComponent />,
    HasPermisson: hasPermission("user-create"),
  },
  {
    exact: true,
    path: "/director",
    element: <DirectorDetails />,
    HasPermisson: hasPermission("regional-director-create"),
  },
  {
    exact: true,
    path: "/gov",
    element: <GovernorDetails />,
    HasPermisson: hasPermission("official-update"),
  },
  {
    exact: true,
    path: "/vicegov",
    element: <ViceGovernorDetails />,
    HasPermisson: hasPermission("official-update"),
  },
  {
    exact: true,
    path: "/sen",
    element: <SenatorDetails />,
    HasPermisson: hasPermission("official-update"),
  },
  {
    exact: true,
    path: "/mayor",
    element: <MayorDetails />,
    HasPermisson: hasPermission("official-update"),
  },
  {
    exact: true,
    path: "/vicemayor",
    element: <ViceMayorDetails />,
    HasPermisson: hasPermission("official-update"),
  },
  {
    exact: true,
    path: "/projects/:id/program/:programID/recipient",
    element: <RecipientDetails />,
    HasPermisson: hasPermission("recipient-update"),
  },
  {
    exact: true,
    path: "/representatives/:id/cong/:congID/recipient",
    element: <RecipientDetails />,
    HasPermisson: hasPermission("recipient-update"),
  },
  {
    exact: true,
    path: "/news",
    element: <NewsAndAnnouncement />,
    HasPermisson: hasPermission("news-view"),
  },
  {
    exact: true,
    path: "/news/add",
    element: <AddAnnouncement />,
    HasPermisson: hasPermission("news-create"),
  },
  {
    exact: true,
    path: "/news/edit",
    element: <AddAnnouncement />,
    HasPermisson: hasPermission("news-update"),
  },
  {
    exact: true,
    path: "/news/view",
    element: <AddAnnouncement />,
    HasPermisson: hasPermission("news-view"),
  },
  {
    exact: true,
    path: "/logs",
    element: <NotificationsLogs />,
    HasPermisson: hasPermission("logs-view"),
  },
  {
    exact: true,
    path: "/national",
    element: <NationalAgenciesComponent />,
    HasPermisson: hasPermission("national-agency-user-view"),
  },
];
