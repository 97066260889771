import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Col, Row, Spinner } from "reactstrap";
import { useLocation, useNavigate } from "react-router";
import {
  statuses,
  SelectDropdownIndicator,
  canEditRoles,
  fundSourceOptions,
  addProjectDropdownStyles,
  DropdownIndicator,
  institutionTypes,
  addProjectSearchInputStyle,
  congRoles,
  fundSourceDBMOptions,
} from "utility/constants";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { fetchAllRegions } from "services/services.congressman";
import {
  Toast,
  getFormattedContactNumber,
  getFormattedDateString,
  getFormattedOptions,
} from "utility/helpers";
import FormGenertor from "components/customs/form-generator/input";
import classNames from "classnames";
import CustomSelect from "components/customs/dropdown";
import { getUserDetails } from "config";
import {
  fetchInstitutions,
  fetchMunicipalities,
  fetchProvinces,
  updateRow,
} from "services/services.projects";
import { BtnSection, CancelBtn, SaveBtn } from "views/projects/form/style";
import { fetchAtcOptions } from "services/services.directory";
import CustomCreatableSelect from "components/customs/creatable-select";
import { congressmanSearch } from "services/services.navbar";
import CustomDropzone from "views/projects/add/Dropzone";
import { StyledRed } from "views/projects/styled";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { genericPost } from "services/services.general";
import { fetchPendingProjects } from "../../redux/actions/projects";

const RequiredIndicator = <StyledRed>{"*"}</StyledRed>;

const acceptedFileTypes = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const RecipientForm = () => {
  const location = useLocation();
  const { row, agency, program, action } = location.state;

  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [provinceOptions, setProvinceOptions] = React.useState([]);
  const [selectedProvince, setSelectedProvince] = React.useState(null);
  const [selectedMunicipality, setSelectedMunicipality] = React.useState(null);
  const [municipalityOptions, setMunicipalityOptions] = React.useState([]);
  const [atcOptions, setAtcOptions] = React.useState([]);
  const [facilityType, setFacilityType] = React.useState(null);
  const [facilityOptions, setFacilityOptions] = React.useState([]);
  const [selectedAtc, setSelectedAtc] = React.useState(null);
  const [selectedInstitution, setSelectedInstitution] = React.useState(null);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [formFields, setFormFields] = useState({
    ...row,
    allocationContactNumber: getFormattedContactNumber(
      row?.allocationContactNumber
    ),
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = getUserDetails();

  const role = user?.type || null;

  const getStatusOptions = () => {
    let options = statuses;

    if (role === "Director") {
      options = options?.filter(
        (option) =>
          option.value !== "Processed" &&
          option.value !== "In-Progress" &&
          option.value !== "Downloaded"
      );
    }

    if (role === "Agency") {
      options = options?.filter(
        (option) =>
          option.value === "Processed" || option.value === "Downloaded"
      );
    }

    if (role === "Editor") {
      options = options?.filter(
        (option) =>
          option.value === "Requested" ||
          option.value === "Pending" ||
          option.value === "Disapproved"
      );
    }

    return options;
  };

  const handleSearchRecepientOnChange = (option) => {
    setFormFields({
      ...formFields,
      recipient: {
        firstName: option?.firstName,
        lastName: option?.lastName,
        middleInitial: option?.middleInitial,
        fullName: option?.name,
        contact: option?.contact,
        id: option.id,
      },
    });
  };

  const handleFailedRowEdit = () => {
    setFormFields(row);
  };

  const handleRemarks = (text) => {
    genericPost({
      url: "/comment",
      formData: {
        text,
        recipientId: formFields.id,
        newsId: null,
      },
      callback: () => {
        //
      },
    });

    genericPost({
      url: "/recipient/send-resubmission-notification",
      formData: {
        remarks: text,
        recipientId: formFields.id,
      },
      callback: () => {
        //
      },
    });
  };

  const updateRecipientDetails = (formData, recipientId) => {
    updateRow({
      url: `/recipient/${recipientId}`,
      formData,
      callback: ({ isSuccess }) => {
        setIsLoading(false);

        if (isSuccess) {
          setTimeout(() => {
            Toast({
              type: 1,
              content: "Successfully updated recipient details",
            });

            if (
              formFields.status === "For Resubmission" &&
              congRoles.includes(role)
            ) {
              handleRemarks(formData.remarks);
            }

            if (role === "Director") dispatch(fetchPendingProjects());
            const path = window.location.pathname.split("/recipient")[0];

            navigate(path, {
              state: {
                agencyId: agency,
              },
            });
          }, 1500);
        } else {
          handleFailedRowEdit();
        }
      },
    });
  };

  const validate = () => {
    if (
      formFields.allocationType.id === 2 &&
      !/^(639)\d{9}$/.test(formFields.allocationContactNumber)
    ) {
      return "Invalid ATC partner contact";
    }

    if (
      formFields.allocationType.id === 3 &&
      !/^(639)\d{9}$/.test(formFields.allocationContactNumber)
    ) {
      return "Invalid contact number";
    }

    if (
      formFields.allocationType.id === 3 &&
      !formFields.allocationContactName
    ) {
      return "Recipient Contact and Contact Number cannot be empty";
    }

    if (
      Number(agency) === 27 &&
      formFields.allocationType.id !== 2 &&
      (program === 10 || program === 15) &&
      !formFields.institution
    ) {
      return "Name of TVI cannot be empty";
    }

    if (
      Number(agency) === 8 &&
      formFields.allocationType.id !== 2 &&
      !formFields.institution
    ) {
      return "Facility cannot be empty";
    }

    if (
      Number(agency) === 14 &&
      formFields.allocationType.id !== 2 &&
      !formFields.institution
    ) {
      return "Project Title cannot be empty";
    }

    if (
      Number(agency) === 3 &&
      (formFields.allocationType.id !== 2 ||
        formFields.allocationType.id === 3) &&
      !formFields.institution &&
      Number(program) === 14
    ) {
      return "Project Title cannot be empty";
    }

    if (
      Number(agency) === 3 &&
      (formFields.allocationType.id !== 2 ||
        formFields.allocationType.id === 3) &&
      !formFields.otherInfo &&
      Number(program) === 14
    ) {
      return "LGSF Tracking Code cannot be empty";
    }

    if (
      Number(agency) === 14 &&
      formFields.allocationType.id !== 2 &&
      !formFields.justification
    ) {
      return "Justification cannot be empty";
    }

    if (
      Number(agency) === 14 &&
      formFields.allocationType.id !== 2 &&
      canEditRoles.includes(getUserDetails()?.type) &&
      !formFields.fundSource
    ) {
      return "Fund Source cannot be empty";
    }

    if (
      Number(agency) === 14 &&
      formFields.allocationType.id !== 2 &&
      !formFields.implementingOffice
    ) {
      return "Implementing Office is required";
    }

    return true;
  };

  const isProjectTitleShown =
    (Number(agency) === 3 &&
      Number(program) === 14 &&
      (formFields?.allocationType?.id === 1 ||
        formFields?.allocationType?.id === 3)) ||
    (Number(agency) === 14 && formFields?.allocationType?.id !== 2);

  const isTrackingCodeShown =
    Number(agency) === 3 &&
    Number(program) === 14 &&
    (formFields?.allocationType?.id === 1 ||
      formFields?.allocationType?.id === 3);

  const isFundSourceShown =
    [...canEditRoles, ...congRoles].includes(role) &&
    ((Number(agency) === 14 && formFields?.allocationType?.id !== 2) ||
      (Number(agency) === 3 &&
        Number(program) === 14 &&
        formFields?.allocationType?.id !== 2));

  const isSupportingDocumentsShown =
    (Number(agency) === 14 && formFields?.allocationType?.id !== 2) ||
    (Number(agency) === 27 &&
      (Number(program) === 10 || Number(program) === 15));

  const handleOnSumbmit = async () => {
    const isValid = await validate();
    let remarks = "";

    if (typeof isValid === "boolean") {
      if (
        formFields.status === "For Resubmission" &&
        congRoles.includes(role)
      ) {
        const result = await Swal.fire({
          title: "Are you sure you want to proceed?",
          text: "You can only edit this once",
          icon: "warning",
          confirmButtonText: "OK",
          showCancelButton: true,
        });

        if (result.isConfirmed) {
          const { value: text } = await Swal.fire({
            input: "textarea",
            inputLabel: "Remarks",
            inputPlaceholder: "Type your remarks here...",
            inputAttributes: {
              "aria-label": "Type your remarks here",
            },
            showCancelButton: true,
          });

          if (text) {
            remarks = text;
          } else return;
        } else {
          return;
        }
      }

      setIsLoading(true);

      const files = uploadedFiles?.map((file) => file.location) || [];

      const getInstitution = () => {
        let institution = null;

        if (formFields.allocationType.id !== 2 && Number(agency) !== 8) {
          if (
            Number(agency) === 27 &&
            (Number(program) === 10 || Number(program) === 15)
          ) {
            institution =
              formFields.institution?.value ?? formFields.institution?.id;
          }

          if (Number(agency) === 14) {
            institution =
              typeof formFields?.institution === "string"
                ? formFields.institution
                : formFields.institution?.id;
          }

          if (Number(agency) === 3 && Number(program) === 14) {
            institution =
              typeof formFields?.institution === "string"
                ? formFields.institution
                : formFields.institution?.id;
          }
        } else if (Number(agency) === 8) {
          institution =
            formFields.institution?.value ?? formFields.institution?.id;
        } else if (
          Number(agency) === 3 &&
          Number(program) === 14 &&
          formFields.allocationType.id === 3
        ) {
          institution = formFields.institution;
        }

        return institution;
      };

      const formData = {
        allocationContactName:
          formFields.allocationType !== 1
            ? formFields?.allocationContactName
            : null,
        allocationContactNumber:
          formFields.allocationType !== 1
            ? formFields?.allocationContactNumber?.slice(2)
            : null,
        allocationName:
          formFields.allocationType.id === 2
            ? formFields?.allocationName
            : null,
        allocationReferenceKey:
          formFields.allocationType.id === 2 ? selectedAtc?.id : null,
        allocation:
          formFields.allocationType.id === 2
            ? {
                atcId: formFields.allocation?.atcId,
                principalId: formFields.allocation?.principalId,
                principal: {
                  manager: {
                    // eslint-disable-next-line no-nested-ternary
                    firstName: formFields?.allocation?.managerFirstName
                      ? formFields?.allocation?.managerFirstName
                      : formFields?.allocation?.principal?.manager?.firstName
                      ? formFields?.allocation?.principal?.manager?.firstName
                      : null,
                    // eslint-disable-next-line no-nested-ternary
                    lastName: formFields?.allocation?.managerLastName
                      ? formFields?.allocation?.managerLastName
                      : formFields?.allocation?.principal?.manager?.lastName
                      ? formFields?.allocation?.principal?.manager?.lastName
                      : null,
                    // eslint-disable-next-line no-nested-ternary
                    contact: formFields?.allocation?.managerContact
                      ? formFields?.allocation?.managerContact
                      : formFields?.allocation?.principal?.manager?.contact
                      ? formFields?.allocation?.principal?.manager?.contact
                      : null,
                  },
                },
              }
            : null,
        // eslint-disable-next-line camelcase
        budget: formFields.budget_allocation,
        fundSource: isFundSourceShown ? formFields?.fundSource || null : null,
        implementingAgency: formFields.implementing?.id,
        implementingOffice:
          formFields.allocationType.id !== 2 && Number(agency) === 14
            ? formFields?.implementingOffice
            : null,
        justification: formFields?.justification || null,
        institution: getInstitution(),
        ownership:
          Number(agency) === 8 &&
          typeof formFields?.institution?.value === "string"
            ? facilityType?.value
            : null,
        municipalityId:
          selectedMunicipality?.value !== "All Municipalities"
            ? formFields?.municipality?.id
            : null,
        provinceId:
          selectedProvince?.value !== "All Provinces"
            ? formFields?.province?.id
            : null,
        recipient: formFields.recipient,
        slots: formFields.slots,
        otherInfo: isTrackingCodeShown ? formFields?.otherInfo || null : null,
        documents: isSupportingDocumentsShown ? { supporting: files } : null,
        editorNotes: formFields?.notes
          ?.map((note) => note?.value?.trim())
          .join(";"),
        remarks,
      };

      if (
        formFields.status === "For Resubmission" &&
        congRoles.includes(role)
      ) {
        formData.isEdited = 1;
      }

      if (
        Number(agency) === 8 &&
        typeof formFields?.institution?.value === "string"
      ) {
        formData.ownership = facilityType?.value;
      }

      updateRecipientDetails(formData, formFields.id);
    } else {
      Toast({
        type: 4,
        content: isValid,
      });
    }
  };

  const fetchRegions = () => {
    fetchAllRegions({
      callback: (data) => {
        if (data?.length !== 0) {
          const newData = data.filter((region) => region.id !== 18);
          const options = getFormattedOptions("acronym", newData);

          setRegions(options);
          setIsLoading(false);

          const region = options?.find(
            (option) => option.id === formFields?.implementing?.id
          );

          if (region) {
            setSelectedRegion(region);
          } else {
            setFormFields({
              ...formFields,
              implementing: null,
            });
          }
        }
      },
    });
  };

  const fetchAllProvinces = () => {
    fetchProvinces({
      param: formFields?.implementing?.id,
      callback: (data) => {
        if (data.length) {
          // eslint-disable-next-line no-shadow
          const options = data.map(({ id, name, omegaId }) => ({
            label: name,
            value: id,
            omegaId,
          }));

          options.unshift({
            label: "All Provinces",
            value: "All Provinces",
          });

          const option = options?.find(
            (op) => op.value === formFields?.province?.id
          );

          if (option) setSelectedProvince(option);
          else {
            setSelectedProvince(options[0]);
          }
          setProvinceOptions(options);
        } else {
          setProvinceOptions([]);
        }
      },
    });
  };

  const fetchAllMunicipalities = async () => {
    await fetchMunicipalities({
      param: selectedProvince?.value,
      callback: (data) => {
        if (data?.length) {
          // eslint-disable-next-line no-shadow
          const options = data.map(({ id, name, omegaId }) => ({
            label: name,
            value: id,
            omegaId,
          }));

          options.unshift({
            label: "All Municipalities",
            value: "All Municipalities",
          });

          const option = options?.find(
            (op) => op.value === formFields?.municipality?.id
          );

          if (option) setSelectedMunicipality(option);
          else {
            setSelectedMunicipality(options[0]);
          }
          setMunicipalityOptions(options);
        } else {
          setMunicipalityOptions([]);
        }
      },
    });
  };

  const fetchAllInstitutions = async () => {
    const param =
      // eslint-disable-next-line no-nested-ternary
      Number(agency) === 8
        ? formFields?.implementing?.id
          ? `?ownership=${facilityType?.value}&type=Healthcare&regionId=${
              formFields?.implementing?.id || null
            } `
          : `?ownership=${facilityType?.value}&type=Healthcare`
        : "?type=Educational";

    await fetchInstitutions({
      param,
      callback: (data) => {
        if (data.length) {
          const options = data.map((hospital) => ({
            ...hospital,
            id: hospital.id,
            label: hospital.name,
            value: hospital.id,
          }));

          const option = options?.find(
            (op) => op.id === formFields?.institution?.id
          );

          if (option) setSelectedInstitution(option);

          setFacilityOptions(options);
        }
      },
    });
  };

  const fetchAllATC = async () => {
    await fetchAtcOptions({
      params: {
        // eslint-disable-next-line camelcase
        region_id: formFields?.implementing?.omegaId || null,
        // eslint-disable-next-line camelcase
        province_id:
          formFields?.province?.value !== "All Provinces"
            ? formFields?.province?.omegaId
            : null,
        // eslint-disable-next-line camelcase
        municipality_id:
          formFields?.municipality?.value !== "All Municipality"
            ? formFields?.municipality?.omegaId
            : null,
      },
      callback: (data) => {
        const options = data
          ?.filter((atc) => atc.principal)
          ?.map((atc) => ({
            ...atc,
            label: `${atc?.last_name}, ${atc?.first_name}`,
            value: atc?.id,
          }));

        const option = options?.find(
          (op) => op?.label === formFields?.allocationName
        );

        if (option) setSelectedAtc(option);

        setAtcOptions(options);
      },
    });
  };

  const handleCongressmanSearch = _.debounce((inputValue, callback) => {
    congressmanSearch({
      inputValue,
      callback,
    });
  }, 1000);

  const handleUploadedFiles = (link) => {
    setUploadedFiles((prevUploadedFiles) => {
      const fileIndex = prevUploadedFiles.findIndex(
        (file) => file.name === link.name
      );

      if (fileIndex !== -1) {
        const updatedFiles = [...prevUploadedFiles];
        updatedFiles[fileIndex] = link;

        return updatedFiles;
      }

      if (prevUploadedFiles.length < 5) {
        return [...prevUploadedFiles, link];
      }

      Toast({
        type: 4,
        content: "Exceeded file upload limit of 5",
      });

      return prevUploadedFiles;
    });
  };

  const handleDeleteFile = (file) => {
    const newUploadedFiles = uploadedFiles.filter(
      (files) => files.name !== file
    );
    setUploadedFiles(newUploadedFiles);
  };

  const setSupportingDocuments = () => {
    const files = [];

    row?.documents?.supporting?.forEach((fileURL) => {
      const fileArray = fileURL.split("/");

      const file = {
        name: decodeURIComponent(fileArray[fileArray.length - 1]),
        location: fileURL,
        percent: 100,
      };

      files.push(file);
    });

    setUploadedFiles(files);
  };

  useEffect(() => {
    if (row?.editorNotes) {
      const notes = row.editorNotes.split(";").map((note) => ({
        label: note,
        value: note,
      }));

      setFormFields({
        ...formFields,
        notes,
      });
    }
  }, [row]);

  useEffect(() => {
    if (row?.documents?.supporting?.length !== 0) {
      setSupportingDocuments();
    }

    setIsLoading(true);
    fetchRegions();
    fetchAllATC();
  }, []);

  useEffect(() => {
    if (selectedRegion) {
      fetchAllProvinces();

      setSelectedMunicipality(null);
      setSelectedProvince(null);
      fetchAllATC();

      if (Number(agency) === 8) fetchAllInstitutions();
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (selectedProvince) {
      fetchAllMunicipalities();
      setSelectedMunicipality(null);
      fetchAllATC();

      if (selectedProvince.value === "All Provinces") {
        setSelectedMunicipality({
          label: "All Municipalities",
          value: "All Municipalities",
        });
      }
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (selectedMunicipality) {
      fetchAllATC();
    }
  }, [selectedMunicipality]);

  useEffect(() => {
    if (Number(agency) === 27) {
      fetchAllInstitutions();
    }

    if (Number(agency) === 8) {
      if (formFields?.institution) {
        const option = institutionTypes?.find(
          (op) => op.label === formFields?.institution?.ownership
        );

        if (option) setFacilityType(option);
      } else {
        setFacilityType(institutionTypes[0]);
      }
    }
  }, [agency]);

  useEffect(() => {
    if (facilityType) {
      fetchAllInstitutions();
      setSelectedInstitution(null);
    }
  }, [facilityType]);

  return (
    <Formik
      initialValues={formFields}
      enableReinitialize
      onSubmit={handleOnSumbmit}
    >
      {({ handleChange, values }) => (
        <Form id="recipient-form">
          {formFields?.recipient && (
            <Row className="m-0 d-flex justify-content-between align-items-center">
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">
                  {"Recipient"}
                  {RequiredIndicator}
                </h5>
                <p>{"Provide the house member's name"}</p>
              </Col>
              <Col
                xs="12"
                md="6"
              >
                <AsyncSelect
                  className="asyc-dropdown w-100"
                  classNamePrefix="select"
                  placeholder=""
                  styles={addProjectSearchInputStyle}
                  value={{
                    // eslint-disable-next-line max-len
                    label: `${formFields?.recipient?.lastName}, ${formFields?.recipient?.firstName}`,
                    // eslint-disable-next-line max-len
                    value: `${formFields?.recipient?.lastName}, ${formFields?.recipient?.firstName}`,
                  }}
                  onChange={(option) => {
                    handleSearchRecepientOnChange(option);
                  }}
                  loadOptions={handleCongressmanSearch}
                  isDisabled={action !== "edit"}
                />
              </Col>
            </Row>
          )}

          {formFields?.project && (
            <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">{"Program"}</h5>
                <p>{"Provide name of the program"}</p>
              </Col>
              <Col
                xs="12"
                md="6"
              >
                <FormGenertor
                  type="text"
                  name="program"
                  className={classNames("form-control input")}
                  value={values?.project}
                  isDisabled
                />
              </Col>
            </Row>
          )}

          {isProjectTitleShown && (
            <Field name="projectTitle">
              {({ form: { touched, errors } }) => (
                <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
                  <Col
                    xs="12"
                    md="6"
                  >
                    <h5 className="font-weight-bold">
                      {"Project Title"}
                      {RequiredIndicator}
                    </h5>
                    <p>{"Provide the project title"}</p>
                  </Col>
                  <Col
                    xs="12"
                    md="6"
                  >
                    <FormGenertor
                      className={classNames("form-control input", {
                        "is-invalid":
                          errors?.institution && touched?.institution,
                      })}
                      placeholder="Project Title"
                      type="text"
                      onChange={(e) => {
                        const {
                          target: { value },
                        } = e;

                        handleChange(value);

                        setFormFields({
                          ...formFields,
                          institution: value,
                        });
                      }}
                      value={formFields?.institution?.name}
                      isDisabled={action !== "edit"}
                    />

                    <ErrorMessage name="projectTitle">
                      {(msg) => (
                        <small className="field-error text-danger">{msg}</small>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
              )}
            </Field>
          )}

          {isTrackingCodeShown && (
            <Field name="otherInfo">
              {({ form: { touched, errors } }) => (
                <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
                  <Col
                    xs="12"
                    md="6"
                  >
                    <h5 className="font-weight-bold">
                      {"LGSF Tracking Code"}
                      {RequiredIndicator}
                    </h5>
                    <p>{"Provide the  LGSF tracking code"}</p>
                  </Col>
                  <Col
                    xs="12"
                    md="6"
                  >
                    <FormGenertor
                      className={classNames("form-control input", {
                        "is-invalid": errors?.otherInfo && touched?.otherInfo,
                      })}
                      placeholder="LGSF Tracking Code"
                      type="text"
                      onChange={(e) => {
                        const {
                          target: { value },
                        } = e;

                        handleChange(value);

                        setFormFields({
                          ...formFields,
                          otherInfo: value,
                        });
                      }}
                      value={formFields?.otherInfo}
                      isDisabled={action !== "edit"}
                    />

                    <ErrorMessage name="otherInfo">
                      {(msg) => (
                        <small className="field-error text-danger">{msg}</small>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
              )}
            </Field>
          )}

          <Field name="budget">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">
                    {"Budget"}
                    {RequiredIndicator}
                  </h5>
                  <p>{"Provide the respective budget allocation"}</p>
                </Col>
                <Col
                  xs="12"
                  md="6"
                >
                  <FormGenertor
                    placeholder="Budget"
                    className={classNames("form-control input", {
                      "is-invalid":
                        errors?.budget_allocation && touched?.budget_allocation,
                    })}
                    type="number"
                    onChange={(e) => {
                      const { value } = e;

                      setFormFields({
                        ...formFields,
                        // eslint-disable-next-line camelcase
                        budget_allocation: Number(value),
                      });
                    }}
                    value={Number(formFields?.budget_allocation)}
                    isDisabled={
                      action !== "edit" ||
                      formFields.status === "For Resubmission"
                    }
                  />

                  <ErrorMessage name="budget">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Allocation Type"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the allocation type"}</p>
            </Col>
            <Col
              xs="12"
              md="6"
            >
              <FormGenertor
                type="text"
                name="lastName"
                className={classNames("form-control input")}
                value={values?.allocationType?.name}
                isDisabled
              />
            </Col>
          </Row>

          <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Status"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the status of your budget"}</p>
            </Col>
            <Col
              xs="12"
              md="6"
            >
              <CustomSelect
                placeholder=""
                name="status"
                isSearchable={false}
                styles={addProjectDropdownStyles}
                classNamePrefix="select"
                value={statuses.find(
                  (stat) => stat.value === formFields?.status
                )}
                onChange={(option) => {
                  setFormFields({
                    ...formFields,
                    status: option.value,
                  });
                }}
                options={getStatusOptions()}
                components={{ DropdownIndicator }}
                getOptionLabel={(option) => {
                  return option?.displayLabel;
                }}
                isDisabled
              />
            </Col>
          </Row>

          <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">
                {"Implementing Agency"}
                {RequiredIndicator}
              </h5>
              <p>{"Provide the implementing agency"}</p>
            </Col>
            <Col
              xs="12"
              md="6"
            >
              <CustomSelect
                placeholder="Please Select Region"
                name="implementingAgency"
                isSearchable
                styles={addProjectDropdownStyles}
                classNamePrefix="select"
                onChange={(option) => {
                  setSelectedRegion(option);

                  setFormFields({
                    ...formFields,
                    implementing: {
                      id: option?.value,
                      region: option?.label,
                      omegaId: option?.omegaId,
                    },
                  });
                }}
                value={regions?.find(
                  (region) => region.value === formFields?.implementing?.id
                )}
                components={{ DropdownIndicator }}
                options={regions}
                isDisabled={action !== "edit"}
              />
            </Col>
          </Row>

          {Number(agency) === 14 && formFields?.allocationType?.id !== 2 && (
            <Field name="implementingOffice">
              {({ form: { touched, errors } }) => (
                <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
                  <Col
                    xs="12"
                    md="6"
                  >
                    <h5 className="font-weight-bold">
                      {"Implementing Office"}
                      {RequiredIndicator}
                    </h5>
                    <p>{"Provide the implementing office"}</p>
                  </Col>
                  <Col
                    xs="12"
                    md="6"
                  >
                    <FormGenertor
                      className={classNames("form-control input", {
                        "is-invalid":
                          errors?.implementingOffice &&
                          touched?.implementingOffice,
                      })}
                      placeholder="Implementing Office"
                      type="text"
                      onChange={(e) => {
                        const {
                          target: { value },
                        } = e;

                        handleChange(value);

                        setFormFields({
                          ...formFields,
                          implementingOffice: value,
                        });
                      }}
                      value={formFields?.implementingOffice}
                      isDisabled={action !== "edit"}
                    />

                    <ErrorMessage name="implementingOffice">
                      {(msg) => (
                        <small className="field-error text-danger">{msg}</small>
                      )}
                    </ErrorMessage>
                  </Col>
                </Row>
              )}
            </Field>
          )}

          <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Province"}</h5>
              <p>{"Provide the province in your region"}</p>
            </Col>
            <Col
              xs="12"
              md="6"
            >
              <CustomSelect
                placeholder="Please Select a Province"
                name="province"
                isSearchable
                styles={addProjectDropdownStyles}
                classNamePrefix="select"
                onChange={(option) => {
                  setSelectedProvince(option);

                  setFormFields({
                    ...formFields,
                    province: {
                      id: option?.value,
                      name: option?.label,
                      omegaId: option?.omegaId,
                    },
                  });
                }}
                value={selectedProvince}
                components={{ DropdownIndicator }}
                options={provinceOptions}
                isDisabled={action !== "edit"}
              />
            </Col>
          </Row>

          <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Municipality"}</h5>
              <p>{"Provide the municipality in your province"}</p>
            </Col>
            <Col
              xs="12"
              md="6"
            >
              <CustomSelect
                placeholder="Please Select a Municipality"
                name="municipality"
                isSearchable
                styles={addProjectDropdownStyles}
                classNamePrefix="select"
                onChange={(option) => {
                  setSelectedMunicipality(option);

                  setFormFields({
                    ...formFields,
                    municipality: {
                      id: option?.value,
                      name: option?.label,
                      omegaId: option?.omegaId,
                    },
                  });
                }}
                value={selectedMunicipality}
                components={{ DropdownIndicator }}
                options={municipalityOptions}
                isDisabled={action !== "edit"}
              />
            </Col>
          </Row>

          {canEditRoles.includes(getUserDetails()?.type) && (
            <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">{"Editor Notes"}</h5>
                <p>{"Provide editor notes"}</p>
              </Col>
              <Col
                xs="12"
                md="6"
              >
                <CustomCreatableSelect
                  isMulti
                  placeholder="Editor Notes"
                  name="notes"
                  styles={addProjectDropdownStyles}
                  classNamePrefix="select"
                  onChange={(option) => {
                    setFormFields({
                      ...formFields,
                      notes: option,
                    });
                  }}
                  value={formFields?.notes}
                  components={{ DropdownIndicator }}
                  isDisabled={action !== "edit"}
                />
              </Col>
            </Row>
          )}

          {formFields?.allocationType?.id === 2 && (
            <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">
                  {"ATC"}
                  {RequiredIndicator}
                </h5>
                <p>{"Provide the ATC Branch"}</p>
              </Col>
              <Col
                xs="12"
                md="6"
              >
                <CustomSelect
                  placeholder="ATC"
                  name="atc"
                  styles={addProjectDropdownStyles}
                  classNamePrefix="select"
                  onChange={(option) => {
                    if (
                      !option?.principal?.atc_partner_first_name ||
                      option?.principal?.atc_partner_contact?.length < 10
                    ) {
                      Toast({
                        type: 4,
                        content: "ATC contact name and number cannot be empty",
                      });

                      return;
                    }
                    setSelectedAtc(option);

                    setFormFields({
                      ...formFields,
                      // eslint-disable-next-line max-len
                      allocationName: `${option?.last_name}, ${option?.first_name}`,
                      allocationContactNumber: getFormattedContactNumber(
                        option?.principal?.atc_partner_contact
                      ),
                      allocationContactName: option?.principal
                        ?.atc_partner_last_name
                        ? // eslint-disable-next-line max-len
                          `${option?.principal?.atc_partner_first_name} ${option?.principal?.atc_partner_last_name}`
                        : option?.principal?.atc_partner_first_name,
                      allocationReferenceKey: option?.principal?.id,
                      allocation: {
                        atcId: option?.id,
                        principalId: option?.principal?.id,
                        managerFirstName:
                          option?.principal?.atc_partner_manager_first_name,
                        managerLastName:
                          option?.principal?.atc_partner_manager_last_name,
                        managerContact:
                          option?.principal?.atc_partner_manager_contact,
                      },
                    });
                  }}
                  value={selectedAtc}
                  components={{ DropdownIndicator }}
                  options={atcOptions}
                  isDisabled={action !== "edit"}
                />
              </Col>
            </Row>
          )}

          {Number(agency) === 8 && (
            <>
              <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">
                    {"Type of Facility"}
                    {RequiredIndicator}
                  </h5>
                  <p>{"Provide the type of facility"}</p>
                </Col>
                <Col
                  xs="12"
                  md="6"
                >
                  <CustomSelect
                    placeholder="Type of Facility"
                    name="facility"
                    styles={addProjectDropdownStyles}
                    classNamePrefix="select"
                    onChange={(option) => {
                      setFacilityType(option);
                    }}
                    value={facilityType}
                    components={{ DropdownIndicator }}
                    options={institutionTypes}
                    isDisabled={action !== "edit"}
                  />
                </Col>
              </Row>

              <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">
                    {"Name of Facility"}
                    {RequiredIndicator}
                  </h5>
                  <p>{"Provide the name of facility"}</p>
                </Col>
                <Col
                  xs="12"
                  md="6"
                >
                  <CustomCreatableSelect
                    placeholder="Name of Facility"
                    name="facility"
                    styles={addProjectDropdownStyles}
                    classNamePrefix="select"
                    onChange={(option) => {
                      setFormFields({
                        ...formFields,
                        institution: option,
                      });
                    }}
                    value={facilityOptions?.find(
                      (facility) => facility.id === formFields?.institution?.id
                    )}
                    components={{ DropdownIndicator }}
                    options={facilityOptions}
                    isDisabled={action !== "edit"}
                  />
                </Col>
              </Row>
            </>
          )}

          {Number(agency) === 27 &&
            formFields?.allocationType?.id !== 2 &&
            (Number(program) === 10 || Number(program) === 15) && (
              <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">
                    {"Name of TVI"}
                    {RequiredIndicator}
                  </h5>
                  <p>{"Provide the name of the TVI"}</p>
                </Col>
                <Col
                  xs="12"
                  md="6"
                >
                  <CustomCreatableSelect
                    placeholder="Name of TVI"
                    name="tvi"
                    styles={addProjectDropdownStyles}
                    classNamePrefix="select"
                    onChange={(option) => {
                      setSelectedInstitution(option);

                      setFormFields({
                        ...formFields,
                        institution: option,
                      });
                    }}
                    value={selectedInstitution}
                    components={{ DropdownIndicator }}
                    options={facilityOptions}
                    isDisabled={action !== "edit"}
                  />
                </Col>
              </Row>
            )}

          {Number(agency) === 27 &&
            (Number(program) === 10 || Number(program) === 15) && (
              <Field name="slots">
                {({ form: { touched, errors } }) => (
                  <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
                    <Col
                      xs="12"
                      md="6"
                    >
                      <h5 className="font-weight-bold">
                        {"Total Slots"}
                        {RequiredIndicator}
                      </h5>
                      <p>{"Provide the number of slots"}</p>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <FormGenertor
                        placeholder="Slots"
                        className={classNames("form-control input", {
                          "is-invalid": errors?.slots && touched?.slots,
                        })}
                        type="number"
                        onChange={(e) => {
                          const { value } = e;

                          setFormFields({
                            ...formFields,
                            // eslint-disable-next-line camelcase
                            slots: Number(value),
                          });
                        }}
                        value={Number(formFields?.slots)}
                        isDisabled={action !== "edit"}
                      />

                      <ErrorMessage name="slots">
                        {(msg) => (
                          <small className="field-error text-danger">
                            {msg}
                          </small>
                        )}
                      </ErrorMessage>
                    </Col>
                  </Row>
                )}
              </Field>
            )}

          {isFundSourceShown && (
            <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">
                  {"Fund Source"}
                  {RequiredIndicator}
                </h5>
                <p>{"Provide the fund source"}</p>
              </Col>
              <Col
                xs="12"
                md="6"
              >
                <CustomSelect
                  placeholder="Fund Source"
                  name="fundSource"
                  isSearchable
                  styles={addProjectDropdownStyles}
                  classNamePrefix="select"
                  onChange={(option) => {
                    setFormFields({
                      ...formFields,
                      fundSource: option?.label,
                    });
                  }}
                  value={
                    Number(agency) === 14
                      ? fundSourceOptions.find(
                          (options) => options.label === formFields?.fundSource
                        )
                      : fundSourceDBMOptions.find(
                          (options) => options.label === formFields?.fundSource
                        )
                  }
                  components={{
                    DropdownIndicator: SelectDropdownIndicator,
                  }}
                  options={
                    Number(agency) === 14
                      ? fundSourceOptions
                      : fundSourceDBMOptions
                  }
                  isDisabled={action !== "edit"}
                />
              </Col>
            </Row>
          )}

          {Number(agency) === 14 && formFields?.allocationType?.id !== 2 && (
            <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">{"Supporting Documents"}</h5>
                <p>{"Provide supporting documents"}</p>
              </Col>
              <Col
                xs="12"
                md="6"
              >
                <CustomDropzone
                  handleUploadedFiles={handleUploadedFiles}
                  handleDeleteFile={handleDeleteFile}
                  uploadedFiles={uploadedFiles}
                  isDisabled={action !== "edit"}
                  label="Upload Attachments"
                  acceptedFileTypes={acceptedFileTypes}
                  acceptedFileTypeErrorMsg="Accepted files are only xls and xlxs."
                />
              </Col>
            </Row>
          )}

          {Number(agency) === 27 &&
            (Number(program) === 10 || Number(program) === 15) && (
              <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">{"Supporting Documents"}</h5>
                  <p>{"Provide supporting documents"}</p>
                </Col>
                <Col
                  xs="12"
                  md="6"
                >
                  <CustomDropzone
                    handleUploadedFiles={handleUploadedFiles}
                    handleDeleteFile={handleDeleteFile}
                    uploadedFiles={uploadedFiles}
                    isDisabled={action !== "edit"}
                    label="Upload Attachments"
                  />
                </Col>
              </Row>
            )}

          <Field name="justification">
            {({ form: { touched, errors } }) => (
              <Row className="m-0 mt-3 d-flex justify-content-between align-items-start">
                <Col
                  xs="12"
                  md="6"
                >
                  <h5 className="font-weight-bold">{"Justification"}</h5>
                  <p>{"Provide the justification"}</p>
                </Col>
                <Col
                  xs="12"
                  md="6"
                >
                  <FormGenertor
                    className={classNames("form-control input", {
                      "is-invalid":
                        errors?.justification && touched?.justification,
                    })}
                    rows="5"
                    type="textarea"
                    placeholder="Enter Text Here"
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;

                      handleChange(value);

                      setFormFields({
                        ...formFields,
                        justification: value,
                      });
                    }}
                    value={formFields?.justification || ""}
                    isDisabled={action !== "edit"}
                  />

                  <ErrorMessage name="justification">
                    {(msg) => (
                      <small className="field-error text-danger">{msg}</small>
                    )}
                  </ErrorMessage>
                </Col>
              </Row>
            )}
          </Field>

          {formFields?.allocationType?.id === 3 && (
            <>
              <Field name="recipientContact">
                {({ form: { touched, errors } }) => (
                  <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
                    <Col
                      xs="12"
                      md="6"
                    >
                      <h5 className="font-weight-bold">
                        {"Recipient Contact"}
                        {RequiredIndicator}
                      </h5>
                      <p>{"Provide the recipient contact"}</p>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <FormGenertor
                        className={classNames("form-control input", {
                          "is-invalid":
                            errors?.allocationContactName &&
                            touched?.allocationContactName,
                        })}
                        placeholder="Recipient Name"
                        type="text"
                        onChange={(e) => {
                          const {
                            target: { value },
                          } = e;

                          handleChange(value);

                          setFormFields({
                            ...formFields,
                            allocationContactName: value,
                          });
                        }}
                        value={formFields?.allocationContactName}
                        isDisabled={action !== "edit"}
                      />

                      <ErrorMessage name="recipientContact">
                        {(msg) => (
                          <small className="field-error text-danger">
                            {msg}
                          </small>
                        )}
                      </ErrorMessage>
                    </Col>
                  </Row>
                )}
              </Field>

              <Field name="mobile">
                {({ form: { touched, errors } }) => (
                  <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
                    <Col
                      xs="12"
                      md="6"
                    >
                      <h5 className="font-weight-bold">
                        {"Contact Number"}
                        {RequiredIndicator}
                      </h5>
                      <p>{"Provide the number of the recipient"}</p>
                    </Col>
                    <Col
                      xs="12"
                      md="6"
                    >
                      <FormGenertor
                        className={classNames("form-control input", {
                          "is-invalid":
                            errors?.allocationContactNumber &&
                            touched?.allocationContactNumber,
                        })}
                        name="mobile"
                        placeholder="Contact Number"
                        type="mobile"
                        onChange={(value) => {
                          handleChange(value);

                          setFormFields({
                            ...formFields,
                            allocationContactNumber: value,
                          });
                        }}
                        value={formFields?.allocationContactNumber}
                        isDisabled={action !== "edit"}
                      />

                      <ErrorMessage name="mobile">
                        {(msg) => (
                          <small className="field-error text-danger">
                            {msg}
                          </small>
                        )}
                      </ErrorMessage>
                    </Col>
                  </Row>
                )}
              </Field>
            </>
          )}

          <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
            <Col
              xs="12"
              md="6"
            >
              <h5 className="font-weight-bold">{"Date Requested"}</h5>
              <p>{"Provide date requested"}</p>
            </Col>
            <Col
              xs="12"
              md="6"
            >
              <FormGenertor
                type="text"
                name="lastName"
                className={classNames("form-control input")}
                value={getFormattedDateString(
                  values?.dateRequested,
                  "MMM-dd-yyyy"
                )}
                isDisabled
              />
            </Col>
          </Row>

          {formFields?.dateApproved && (
            <Row className="m-0 mt-3 d-flex justify-content-between align-items-center">
              <Col
                xs="12"
                md="6"
              >
                <h5 className="font-weight-bold">{"Date Approved"}</h5>
                <p>{"Provide date approved"}</p>
              </Col>
              <Col
                xs="12"
                md="6"
              >
                <FormGenertor
                  type="text"
                  name="dateApproved"
                  className={classNames("form-control input")}
                  value={getFormattedDateString(
                    values?.dateApproved,
                    "MMM-dd-yyyy"
                  )}
                  isDisabled
                />
              </Col>
            </Row>
          )}

          <BtnSection>
            <CancelBtn
              className="btn-cancel mr-1"
              disabled={isLoading}
              onClick={() => {
                const path = window.location.pathname.split("/recipient")[0];

                navigate(path, {
                  state: {
                    agencyId: agency,
                  },
                });
              }}
            >
              {"Back"}
            </CancelBtn>

            <SaveBtn
              type="submit"
              className="btn-save"
              disabled={isLoading || action !== "edit"}
            >
              {isLoading ? (
                <Spinner
                  animation="border"
                  color="light"
                  size="sm"
                />
              ) : (
                "Save"
              )}
            </SaveBtn>
          </BtnSection>
        </Form>
      )}
    </Formik>
  );
};

export default RecipientForm;
